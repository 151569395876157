import { Box, Flex, Grid, Heading, Text } from '@chakra-ui/react';
import { graphql } from 'gatsby';
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image';
import React from 'react';
import TargetAudience from '../../components/alliances/target-audience';
import ContactForm from '../../components/contact/contact-form';
import SEO from '../../components/seo';

function InHouseTrainingPage({ data }) {
  const seoDescription =
    data.pageSeo.edges[0].node.description.internal.content;
  const headerImage = getImage(data.header.nodes[0]?.headerImage?.gatsbyImage);
  const { heading } = data.header.nodes[0];
  const intro = data.header.nodes[0].introParagraph.internal.content;
  return (
    <>
      <SEO title="In-House Training" description={seoDescription} />
      <Flex
        flexDir="column"
        justify="start"
        align="center"
        w="100%"
        mt={8}
        mb={16}
      >
        <Flex
          justifyContent="space-between"
          alignItems="flex-start"
          flexDir={{ base: 'column', md: 'row' }}
        >
          <Flex
            flexDir="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            w={{ base: '100%', '2xl': '50%' }}
            mr={{ base: 0, md: 20 }}
            order={{ base: 2, md: 1 }}
          >
            <Heading as="h1" mt={10}>
              {heading}
            </Heading>
            <Text mt={3} color="gray.600">
              {intro}
            </Text>
          </Flex>
          <Box
            w={{ base: '100%', '2xl': '50%' }}
            overflow="hidden"
            boxShadow="lg"
            borderRadius="lg"
            mt={10}
            order={{ base: 1, md: 2 }}
          >
            <Img
              image={headerImage}
              alt="Child psychologist and parenting expert Michael Hawton teaching."
            />
          </Box>
        </Flex>
        <Grid
          templateColumns={{ base: '1fr', lg: 'repeat(3, 1fr)' }}
          gap={5}
          my={{ base: 12, md: 32 }}
        >
          <TargetAudience
            title="education"
            body="If you’re a leaders’ association, a district of schools, or an individual school we can work with you to tailor solutions to solve problems in schools. For example, we currently work with principals’ organisations to provide in-school interventions, like teacher-led anxiety programs for use in Australian classrooms."
            link="for-professionals"
            image={getImage(data.education.childImageSharp.gatsbyImageData)}
            alt="Parentshop run in-house education courses for primary and secondary schools."
          />
          <TargetAudience
            title="community"
            body="If you’re a peak group for an Australian community organisation, NGO, or a state-based organisation we can work with you to set-up population-based interventions or system-wide training."
            link="for-professionals"
            image={getImage(data.community.childImageSharp.gatsbyImageData)}
            alt="Parentshop run in-house community courses for Australian community organisations and NGOs."
          />
          <TargetAudience
            title="early years educators"
            body="Parentshop provides tailored solutions for early years educators, community and early years centres in early intervention strategies for behaviour management and self-regulation and anxiety management. Contact us to see how we could tailor a program to suit your centre."
            link="professionals/early-years"
            image={getImage(data.earlyYears.childImageSharp.gatsbyImageData)}
            alt="Parentshop run in-house early years educators courses for in-house at early-years child centres."
          />
        </Grid>
        <Flex justifyContent="space-between" alignItems="flex-start" w="100%">
          <Flex
            flexDir="column"
            justify="start"
            align="start"
            w={{ base: '100%', lg: '50%' }}
            mr={{ base: 0, lg: 20 }}
          >
            <Heading as="h2" fontWeight="semibold">
              Want to know more? Contact us
            </Heading>
            <ContactForm formName="in-house-training" />
          </Flex>
          <Box
            w="50%"
            display={{ base: 'none', lg: 'block' }}
            borderRadius="md"
            overflow="hidden"
            boxShadow="lg"
          >
            <Img
              image={getImage(data.contact.childImageSharp.gatsbyImageData)}
              alt="contact"
            />
          </Box>
        </Flex>
      </Flex>
    </>
  );
}

export default InHouseTrainingPage;

export const query = graphql`
  query {
    pageSeo: allContentfulPageSeo(
      filter: { pageName: { eq: "in-house-training" } }
    ) {
      edges {
        node {
          pageName
          description {
            internal {
              content
            }
          }
        }
      }
    }
    header: allContentfulPageHeader(
      filter: { heading: { eq: "In-house training" } }
    ) {
      nodes {
        headerImage {
          gatsbyImage(width: 1920)
        }
        heading
        introParagraph {
          internal {
            content
          }
        }
      }
    }
    contact: file(relativePath: { eq: "contact/contact-books.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 720)
      }
    }
    education: file(relativePath: { eq: "alliances/education.png" }) {
      childImageSharp {
        gatsbyImageData(width: 720)
      }
    }
    community: file(relativePath: { eq: "alliances/community.png" }) {
      childImageSharp {
        gatsbyImageData(width: 720)
      }
    }
    earlyYears: file(relativePath: { eq: "alliances/early-years.png" }) {
      childImageSharp {
        gatsbyImageData(width: 720)
      }
    }
  }
`;
